$shelf-window-padding: 15px;

.kendo-window {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  transform: rotate(180deg);
  z-index: 999999;
}

.global-window-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.shelf-window:not(.k-window-maximized) {
  width: var(--shelf-window-width) !important;
  height: var(--shelf-window-height) !important;
  transform: rotate(180deg) var(--shelf-window-transform) !important;
}

.k-window-maximized {
  top: $shelf-window-padding !important;
  left: $shelf-window-padding !important;
  width: calc(100dvw - ($shelf-window-padding * 2)) !important;
  height: calc(100dvh - ($shelf-window-padding * 2)) !important;
  box-shadow: 22px 0px 0px 147px rgba(0, 0, 0, 0.28) !important;
}

.grid-borderless {
  .k-table-th {
    border: none !important;
  }
  .k-table-td {
    border: none !important;
    border-bottom: 1px #ebebeb solid !important;
  }

  &.k-grid {
    border-inline: none !important;
    border-top: none !important;
  }

  col.k-sorted,
  .k-table-th.k-sorted {
    background: initial !important;
  }
}

.raven-custom-dialog {
  .k-window-content {
    padding: 0 !important;
  }
}

.k-dialog-actions {
  border-color: rgba(0, 0, 0, 0.08) !important;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 4px;
  }
}

.custom-scroll-on-hover {
  scrollbar-gutter: stable both-edges;

  margin-left: -0.5rem;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #b9b9b9;
    }
  }
}
//
//.scroll-on-hover {
//  &::-webkit-scrollbar {
//    background-color: transparent !important;
//  }
//
//  &:hover {
//    &::-webkit-scrollbar-thumb {
//      background: #b9b9b9;
//    }
//  }
//}
//.scroll-on-hover:hover {
//  &::-webkit-scrollbar-thumb {
//    background: #b9b9b9;
//  }
//}

.cropped-kendo-button {
  .k-button-text {
    max-width: 100%;
  }
}
