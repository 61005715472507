@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/styles/variables';
@import 'assets/styles/kendo-theme';
@import 'assets/styles/shelf';
@import 'assets/styles/kendo-editor';

// When you are finished with your customization, import the theme
@import '@progress/kendo-theme-default/dist/all.scss';

@import 'assets/fontawesome/scss/fontawesome';
@import 'assets/fontawesome/scss/brands';
@import 'assets/fontawesome/scss/solid';
@import 'assets/fontawesome/scss/regular';

// UNCOMMENT THE FOLLOWING LINES TO INCLUDE ALL ICONS
// @import 'assets/fontawesome/scss/light';
// @import 'assets/fontawesome/scss/thin';
// @import 'assets/fontawesome/scss/duotone';
@import 'assets/fontawesome/scss/sharp-solid';

@import 'assets/interstate/scss/interstate';

/* You can add global styles to this file, and also import other style files */

router-outlet.router-flex + * {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

body {
  @apply text-text;

  font-family: interstate, sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 100vh;
  overflow: hidden;
}

.k-badge-edge {
  z-index: 1;
}

a.tag-link {
  &:hover {
    text-decoration: underline;
  }
}

.k-popover {
  background-color: var(--rv-primary-tooltip);
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  &-callout {
    width: 18px;
    height: 18px;
  }
}
.k-popover-header {
  padding: 0;
  border: none;
}

.grid-borderless {
  .k-grid-header-wrap {
    border: none;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
