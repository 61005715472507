@font-face {
  font-family: interstate;
  font-style: normal;
  font-weight: 300;
  src: url('../webfonts/31139.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: italic;
  font-weight: 300;
  src: url('../webfonts/31140.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: normal;
  font-weight: 400;
  src: url('../webfonts/31141.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: italic;
  font-weight: 400;
  src: url('../webfonts/31142.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: normal;
  font-weight: 700;
  src: url('../webfonts/31145.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: italic;
  font-weight: 700;
  src: url('../webfonts/31146.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: normal;
  font-weight: 800;
  src: url('../webfonts/31147.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: italic;
  font-weight: 800;
  src: url('../webfonts/31148.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: normal;
  font-weight: 900;
  src: url('../webfonts/31149.otf') format('opentype');
}

@font-face {
  font-family: interstate;
  font-style: italic;
  font-weight: 900;
  src: url('../webfonts/31150.otf') format('opentype');
}
