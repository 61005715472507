@use 'sass:color';

$primary-color: #00a59b;
$secondary-color: #002c3c;
$tertiary-color: #9bcbeb;
$light: #d9d9d6;
$dark: #424242;
$primary-tooltip-color: #ebf8f7;

$error-color: #c52a1a;
$informational-error-color: #c52a1a;
$info-color: #0058e9;
$success-color: #37b400;
$warning-color: #ffc000;

//INFORMATIONAL COLORS
$informational-error-color: #c52a1a;
$informational-success-color: #12861e;
$informational-warning-color: #ffbf1f;
$informational-info-color: #008387;

$informational-variables: (
  informational-error: $informational-error-color,
  informational-success: $informational-success-color,
  informational-warning: $informational-warning-color,
  informational-info: $informational-info-color,
);

//SERIES COLORS
$kendo-series-a: #00ccbe;
$kendo-series-b: #004b66;
$kendo-series-c: #b4e4e2;
$kendo-series-d: #77b8e4;
$kendo-series-e: #f0882d;
$kendo-series-f: #d84661;
$kendo-series-g: #d281d9;
$kendo-series-h: #fddc49;

$series-variables: (
  series-a: $kendo-series-a,
  series-b: $kendo-series-b,
  series-c: $kendo-series-c,
  series-d: $kendo-series-d,
  series-e: $kendo-series-e,
  series-f: $kendo-series-f,
  series-g: $kendo-series-g,
  series-h: $kendo-series-h,
);

@function k-color-mix($color1, $color2, $weight: 50%) {
  @return color.mix($color1, $color2, $weight);
}

:root {
  // APP MAIN COLORS
  --rv-primary: #{$primary-color};
  --rv-secondary: #{$secondary-color};
  --rv-tertiary: #{$tertiary-color};
  --rv-light: #{$light};
  --rv-dark: #{$dark};
  --rv-primary-tooltip: #{$primary-tooltip-color};

  --rv-error: #{$error-color};
  --rv-info: #{$info-color};
  --rv-success: #{$success-color};
  --rv-warning: #{$warning-color};

  --rv-base-border-radius: 4px;
  --rv-sm-border-radius: 2px;
  --rv-bg-border-radius: 6px;

  // BUTTONS
  --rv-primary-button-bg: #00a59b;
  --rv-button-text-20: #002c3c;

  // RAVEN COLORS

  // SERIES COLORS
  @each $name, $value in $series-variables {
    --#{$name}: #{$value};
    --#{$name}-darken-75: #{k-color-mix(black, $value, 25%)};
    --#{$name}-darken-50: #{k-color-mix(black, $value, 50%)};
    --#{$name}-lighten-75: #{k-color-mix(white, $value, 25%)};
    --#{$name}-lighten-50: #{k-color-mix(white, $value, 50%)};
    --#{$name}-lighten-20: #{k-color-mix(white, $value, 80%)};
  }

  // INFORMATIONAL COLORS
  @each $name, $value in $informational-variables {
    --#{$name}: #{$value};
    --#{$name}-8: #{k-color-mix(white, $value, 92%)};
    --#{$name}-16: #{k-color-mix(white, $value, 84%)};
    --#{$name}-24: #{k-color-mix(white, $value, 76%)};
    --#{$name}-30: #{k-color-mix(white, $value, 70%)};
    --#{$name}-50: #{k-color-mix(white, $value, 50%)};
  }
}
